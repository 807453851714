
import { defineComponent, nextTick, onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiTaskManagement, ApiBase } from "@/core/api";
import Editor from "@tinymce/tinymce-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";

export default defineComponent({
  name: "standard-script-information",
  components: {
    editor: Editor,
  },
  setup() {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const scrpitRef = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: route.params.id,
      system_status: 10,
      subject: "",
      type: "",
      suitable_conditions: [],
      suitable_account: [],
      script: "",
      created_at: null,
      updated_at: null,
      created_uid: "",
      updated_uid: "",
      __show: {
        created_user: "",
        updated_user: "",
      },
    });
    const { t } = useI18n();

    const rules = ref({
      subject: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      suitable_conditions: [
        {
          required: true,
          // asyncValidator: (rule, value) => {
          //   return new Promise<void>((resolve, reject) => {
          //     if (formData.value.type != "1") {
          //       reject(t("common.isRequired"));
          //     } else {
          //       resolve();
          //     }
          //   });
          // },
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const subjectOptions = ref([]);
    const subjectLoading = ref<boolean>(false);
    const typeOptions = ref([
      // { label: "Account Validation", value: "1" },
      // { label: "Call-Out", value: "2" },
      // { label: "Quick Inquiry", value: "3" },
    ]);
    const typeLoading = ref<boolean>(false);
    const callOutSuitableConditionsOptions = ref([]);
    const ouickInquirySuitableConditionsOptions = ref([]);
    const suitableConditionsLoading = ref<boolean>(false);
    const suitableAccountOptions = ref([]);
    const suitableAccountLoading = ref<boolean>(false);

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "quick_inquiry_comment_tagging",
          "call_out_subject",
          "distribution_task_type",
        ],
      });
      if (data.code == 0) {
        callOutSuitableConditionsOptions.value =
          data.data.quick_inquiry_comment_tagging.items;
        ouickInquirySuitableConditionsOptions.value =
          data.data.call_out_subject.items;
        typeOptions.value = data.data.distribution_task_type.items;
      }
    };

    const typeChange = () => {
      formData.value.suitable_conditions = [];
    };

    const getStandardScriptInfo = async () => {
      loading.value = true;
      const { data } = await ApiTaskManagement.getStandardScriptInfo({
        id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value = data.data;
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }

          ApiTaskManagement.updateStandardScript(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const deleteStandardScript = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Standard Script?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiTaskManagement.deleteStandardScript({
            id: route.params.id,
          })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Standard Script has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "standard-script" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
    });

    onBeforeMount(() => {
      getStandardScriptInfo();
      getDropdownOptions();
    });

    return {
      loading,
      formData,
      rules,
      formRef,
      submitButton,
      deleteButton,
      scrpitRef,
      subjectOptions,
      subjectLoading,
      typeOptions,
      typeLoading,
      typeChange,
      callOutSuitableConditionsOptions,
      ouickInquirySuitableConditionsOptions,
      suitableConditionsLoading,
      suitableAccountOptions,
      suitableAccountLoading,
      submit,
      deleteStandardScript,
      t,
    };
  },
});
